@import "../../style/custonVariable";

.langContainer {
    min-height: 50px;
    min-width: 70px;
    margin-left: -30px;

    .langButton {
        position: relative;
        display: block;
        cursor: pointer;
        height: 50px;

        &.mainlayout {
            padding-top: 10px;
        }

        &:hover {
            background: transparent;
        }
    }

    @media (max-width: 991px) {
        min-width: 60px;
    }

    .langFlag {
        width: 21px;
    }

    .selectedLang {
        position: absolute;
        width: 100%;
        min-height: -webkit-fill-available;
        padding: 15px;
        vertical-align: middle;
    }

    .caretIcon {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 5px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }

    .languageDropDown {
        top: 50px !important;
        position: absolute;
    }
}


//page title style
.pageTitle {
    min-height: 37px;
    background: transparent;
    //border-top: 2px solid #dee5e7;
    color: #243040;
    position: relative;
    // margin-top:20px;
    z-index: 2;

    //box-shadow: 0 8px 6px -6px #dee5e7;
    .pagetTileText {
        font-size: 24px;
        color: inherit;
        font-weight: 500;
        position: absolute;
        padding: 15px;

        @media (max-width:600px) {
            padding: 15px 15px;
        }
    }

    .ModalButtonGroup {
        float: right;
        padding: 5px;

        @media (max-width:600px) {
            padding: 10px 10px;
        }

        border: none;

        .MainButton {
            padding: 10px;
        }

        .splitIcon {
            background-color: #564d8c !important;
            border-color: #564d8c !important;
        }
    }
}


//language container
.currContainer {
    min-height: 50px;
    min-width: 70px;
    width: auto;

    @media (max-width: 991px) {
        min-width: 60px;
    }

    .currButton {
        position: relative;
        display: block;
        cursor: pointer;
        height: 50px;
    }

    .caretIcon {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 5px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }

    .selectedCurr {
        position: absolute;
        width: 100%;
        min-height: -webkit-fill-available;
        padding: 15px;
        vertical-align: middle;
        text-align: center;
    }

    &:hover {
        background: transparent;
    }

    .currencyDropDown {
        top: 50px !important;
        position: absolute;
    }

    .dropdownItem {
        font-size: 14px !important;
    }
}


//input field
.required::after {
    content: "*";
    color: red;
    padding-left: 5px;
}

.formsInputField {
    // background-color: #f7f7f7 !important;
    padding: 5px 12px !important;
    border-radius: 3px !important;
    border: 1px solid #cfdadd;
    height: $inputField-height;
}

.dropdownField {
    height: $inputField-height;
}

.FormsHasError {
    box-shadow: none;
    border: 1px solid #dc3545 !important;
}

.isInvalid {
    margin-top: 3px;
    color: #a94442;
}

.textSuccess {
    &img {
        width: 17px;
    }
}

.button {
    display: inline-block;
    padding: $button-padding;
    margin-bottom: $button-margin-bottom;
    font-size: $button-font-size;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    font-weight: 500;
    font-family: $font-mulish;
}

.datePicker {
    width: 100%;
    z-index: 1000 !important;
    height: 34px;
    font-size: 0.75rem;
    color: black !important;
    padding: 5px 12px !important;
    border-radius: 3px !important;
    border: 1px solid #cfdadd !important;
    height: 34px;

    &:disabled {
        cursor: not-allowed;
    }
}

.datePicker:focus {
    border: none;
}

.PaymentMethods {
    margin-top: 20px;
    margin-bottom: 60px;
    height: auto;
    //border: 1px solid #ccc;
    display: flex;

    //background-color: $color-white;
    @media (max-width: 991px) {
        display: block;
    }

    .tab {
        // float: left;
        // background-color: #f1f1f1;
        width: 25%;
        height: auto;
        border: 1px solid #ccc;
        text-align: center;

        @media (max-width: 991px) {
            width: 100%;
        }

        button {
            display: block;
            padding: 10px 15px;
            display: block;
            margin-bottom: -1px;
            //background-color: #fff;
            border: 1px solid #ddd;
            color: #5A55A3;
            width: 100%;
            outline: none;
            text-align: center;
            cursor: pointer;
            transition: 0.3s;
            font-size: 17px;
            position: relative;

            &:hover {
                background-color: #ddd;
            }

            &.active {
                background-color: #23b7e5 !important;
                border-color: #23b7e5 !important;
                color: $color-white;

                &::after {
                    content: '';
                    position: absolute;
                    left: 100%;
                    top: 50%;
                    margin-top: -13px;
                    border-left: 0;
                    border-bottom: 13px solid transparent;
                    border-top: 13px solid transparent;
                    border-left: 10px solid #41b9e6;
                }
            }
        }
    }

    .tabcontent {
        float: left;
        border: 1px solid #ccc;
        width: 75%;
        border-left: none;
        height: auto;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;

        // background-color: $color-white;
        @media (max-width: 991px) {
            width: 100%;
        }
    }
}

// Addon notification
.addonNotification {
    margin: 0 auto 15px;

    .addonDescription {
        padding: 5px;
        display: grid;
        grid-template-columns: 35px 1fr;
        align-items: center;

        div {
            display: flex;
            align-items: center;
            grid-gap: 5px;
            gap: 5px;
        }

        .icon {
            font-size: 30px;
            color: #ffad31 !important;

        }

        @media only screen and (max-width: 600px) {
            margin-top: 20px;
        }
    }
}

.formGroup {
    //margin-bottom: 4px;
    margin-bottom: 20px;
    // margin-top: 20px;
}

//tool TIps
.tooltipHeader {
    width: 100%;
    background-color: #40b7e5;
    padding: 10px 5px 3px 5px;
    color: $color-white;

    img {
        width: 60px !important;
        height: 60px !important;
        border-radius: 50% !important;
        border: 1px solid #0bb4f5;
        background: #fff;
        padding: 4px;
    }

    p {
        color: $color-white;
    }
}

.tooltipList {
    padding: 8px 10px;

    .tooltipListItem {
        text-align: initial;
        padding: 0px;
        display: grid;
        border-radius: 0;
        position: relative;
        // padding: 4px 0px 4px 0px;
        margin-bottom: 0;
        background-color: $color-white;
        border: 0px solid #ddd;
        grid-template-columns: 1fr 1fr;
    }

}

.FormField {
    height: 34px;
}

.prepend {
    height: 34px;
}

.btnAddon {
    i {
        position: relative;
        float: left;
        width: 34px;
        height: 34px;
        margin: -12px;
        margin-right: 5px;
        line-height: 34px;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 2px 0 0 2px;
    }
}

.btnInfo {
    background: $color-info;
    border-color: $color-info;
    height: 34px;

    &:not(:disabled):not(.disabled):active,
    &:hover,
    &:disabled {
        background: $color-info;
        border-color: $color-info;
    }
}

.DateRangePicker {
    background: #fff;
    cursor: pointer;
    padding: 5px;
    border: 1px solid #ccc;
    min-width: 10%;
    max-width: max-content;
    display: inline-block;
    margin-right: 5px;

    @media (max-width: 767px) {
        margin-bottom: 5px;
        width: 100%;
        max-width: none;
    }
}

.customBtn {
    font-size: 0.875rem;
    line-height: 1;
    font-family: "Roboto", sans-serif;
    height: 34px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.4rem 1rem;
    font-size: 0.875rem;
    line-height: 1;
    border-radius: 0.1875rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin: 2px;
    margin-bottom: 5px;

    &.primary {
        background-color: $color-primary;
        color: $color-white;
    }

    &.info {
        background-color: $color-info;
        color: $color-white;

        &:hover {
            background-color: #19a9d5;
        }
    }

    &.success {
        background-color: $color-success;
        color: $color-white;
    }
}

.btnGrop {
    height: 34px;
    margin-bottom: 5px;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    margin: 2px;
    margin-bottom: 5px;

    &.primary {
        background-color: $color-primary;
        color: $color-white;
    }

    &.info {
        background-color: $color-info;
        color: $color-white;

        &:hover {
            background-color: #19a9d5;
        }
    }

    &.success {
        background-color: $color-success;
        color: $color-white;
    }

    i {
        width: 30px;
        height: 30px;
        margin: -6px -12px;
        margin-right: 10px;
        line-height: 30px;
        position: relative;
        float: left;
        width: 34px;
        height: 34px;
        margin: -7px -12px;
        margin-right: 12px;
        line-height: 34px;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 2px 0 0 2px;
    }
}

.backButton {
    text-align: right;
}

.datePickerForm {
    position: relative;

    &::before {
        content: "\f073";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        color: #000;
        font-size: 10px;
        // padding: 0.5em;
        position: absolute;
        top: 5px;
        left: 10px;
    }

    .dateInput {
        padding-left: 35px !important;
    }
}

.top_notification_sec {
    display: inline-block;

    .dropdown {
        height: 46px;
        width: 48px;
    }
}

.earn_n_team_activee-tabs::before {
    transform: inherit !important;
    top: 0 !important;
}

// .pageTitle span{display: none;} 

@media screen and (max-width:767px) {

    //.langContainer{display: none;}
    .currContainer {
        display: none;
    }

    .content-wrapper {
        padding-left: 60px !important;
    }

    //.loginContainer .langContainer{display: block !important;}
}

.page_title_text {
    margin-left: 20px;
    position: relative;
    top: 10px;
    font-size: 19px;
    color: #242424;
    // font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
}


.notreqnow {
    background-color: #eee;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
}

.notreqnow2 {
    text-align: center;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.reqepindatevalid {
    position: relative;
    top: -19px;
}

.langContainer {
    padding-left: 30px;
}

.dark_mode_page_title_text {
    margin-left: 20px;
    position: relative;
    top: 10px;
    font-size: 19px;
    color: white !important;
    // font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.dark_mode_text {
    color: "black"
}


.dark_mode_tooltipList {
    padding: 8px 10px;

    .dark_mode_tooltipListItem {
        text-align: initial;
        padding: 0px;
        display: grid;
        border-radius: 0;
        position: relative;
        // padding: 4px 0px 4px 0px;
        margin-bottom: 0;
        //background-color: $color-white;
        border: 0px solid #ddd;
        grid-template-columns: 1fr 1fr;
    }

    .dark_mode_tooltipListItem div {
        color: #fff !important;
    }

    .logoutButton {
        display: block;
        padding: 10px 15px;
        display: block;
        margin-bottom: -1px;
        //background-color: #fff;
        border: 1px solid #ddd;
        color: #5A55A3;
        width: 100%;
        outline: none;
        text-align: center;
        cursor: pointer;
        transition: 0.3s;
        font-size: 17px;
        position: relative;

        &:hover {
            background-color: #ddd;
        }

        &.active {
            background-color: #23b7e5 !important;
            border-color: #23b7e5 !important;
            color: $color-white;

            &::after {
                content: '';
                position: absolute;
                left: 100%;
                top: 50%;
                margin-top: -13px;
                border-left: 0;
                border-bottom: 13px solid transparent;
                border-top: 13px solid transparent;
                border-left: 10px solid #41b9e6;
            }
        }
    }

    // .logoutButton {
    //     font-size: 20px;
    //     margin-right: 30px;
    //     border-radius: 30px;
    //     background-color: #FFCCCB;
    //     margin-top: 10px;
    //     z-index: 999;
    //     :hover {
    //         color:yellow;
    //     }
    // }

}
.text_right{text-align: right;}
.text_right span{float: left;}